import React from "react"

const SectionBreak = (props) => (
    <div style={{
        marginBottom: props.size ? props.size : 120,
    }}>
    </div>

)

export default SectionBreak
