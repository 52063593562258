import React from "react"
import { css } from '@emotion/css'

const SectionImage = (props) => (
    <div 
    
    className={css`
        height: ${props.children ? `auto` : props.height ? `${props.height}px` : `600px`};
        height: ${props.big ? `800px` : null};
        background-image: url(${props.imageUrl});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        color: white;

        @media (max-width: 1000px) {
            height: ${props.children ? `auto` : `500px`};

        }

        @media (max-width: 565px) {
            height: ${props.children ? `auto` : `320px`};

        }
    `}
    >
        <div style={{
            width: '100%',
            height: '100%',
            backgroundColor: props.overlay ? `rgba(0, 0, 0, 0.6)` : null,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '40px 0',
        }}>
            {props.children}
        </div>
    </div>
)

export default SectionImage