import React from "react"
import { css } from '@emotion/css'
import WhiteOverlayHero from "../shared/WhiteOverlayHero"
import TopSectionMobile from "../shared/TopSectionMobile"
import SectionImage from "../shared/SectionImage"
import Layout from "../shared/Layout2"
import Navbar from "../shared/Navbar"
import Section from "../shared/Section"
import SectionTitle from "../shared/SectionTitle"
import LightText from "../shared/LightText"
import BigTitle from "../shared/BigTitle"
import MainButton from "../shared/MainButton"
import Container from "../shared/Container"
import DownArrow from "../shared/DownArrow"
import BlueSection from "../shared/BlueSection"
import BigIcon from "../shared/BigIcon"
import EndSection from "../shared/EndSection"
import SEO from "../shared/seo"
import { Link, Element } from 'react-scroll'
import Zoom from 'react-reveal/Zoom';
import NavbarNew from "../shared/NavbarNew"
import SectionBreak from "../shared/SectionBreak"

const CheckIconText = (props) => {
    return <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '10px' }}>
            <LightText color="white" text="✓" />
        </div>
        <LightText color="white" text={props.text} />
    </div>
}

const ValuationIcon = (props) => {
    return (
        <Zoom>
            <div style={{
                textAlign: 'center',
                marginBottom: '30px',
            }}>
                <img
                    src={props.iconUrl}
                    alt="hand"
                    style={{
                        width: '100px',
                    }}
                />
                <h2 style={{ fontSize: '1.4em', color: props.color, fontWeight: 'bold' }}>{props.text}</h2>
            </div>
        </Zoom>
    )
}

const AboutComponent = ({cmsData}) => {

    return <Layout>
        <NavbarNew darkMode />

        <SEO 
            title={cmsData.seo.title == "-" ? "" : cmsData.seo.title}
            description={cmsData.seo.descr == "-" ? "" : cmsData.seo.descr}
        />

        <div className={css`
            display: none;
            @media (max-width: 1000px) {
                display: block;
            }
        `}>
            <TopSectionMobile imageUrl={cmsData?.hero?.bg?.childImageSharp?.fluid?.src}>
                <SectionTitle text={cmsData?.hero?.title} />
                {cmsData?.hero?.heroTextList?.map(block => {
                    return <LightText text={block.text} />
                })}
                <Link to="firstSection" spy={true} smooth={true} duration={800}>
                    <DownArrow />
                </Link>
            </TopSectionMobile>
        </div>

        <div className={css`
            @media (max-width: 1000px) {
                display: none;
            }
        `}>
            <WhiteOverlayHero imageUrl={cmsData?.hero?.bg?.childImageSharp?.fluid?.src}>
                <SectionTitle text={cmsData?.hero?.title} />
                {cmsData?.hero?.heroTextList?.map(block => {
                    return <LightText text={block.text} />
                })}
                <Link to="firstSection" spy={true} smooth={true} duration={800}>
                    <DownArrow />
                </Link>
            </WhiteOverlayHero>
        </div>

        {cmsData?.content?.map((block,i) => {
              //  console.log(block.iconOverTitle.);

            return block.type == "simple" ? 
                i == 0 ?

            <Element name="firstSection" >
                <BlueSection>
                    <Container>
                    {block.simple[0].iconOverTitle?.arp?.fluid?.src.includes("null") ? 
                    null
                    : 
                    <BigIcon iconUrl={block.simple[0].iconOverTitle?.childImageSharp?.fluid?.src} /> 
                    }
                        <SectionTitle text={block.simple[0].title} />
                        {block?.simple[0]?.textSection?.map(text => {
                            return <LightText text={text.text} />
                        })}
                        <SectionBreak size={80} />
                        <MainButton to={block.simple[0].buttonPath} text={block.simple[0].buttonText} />
                    </Container>
                </BlueSection>
            </Element>
            :
            <Section background={block.simple[0].bgColor != "-" ? block.simple[0].bgColor : null}>
                <Container>
                    {block.simple[0].iconOverTitle?.childImageSharp?.fluid?.src.includes("null") ? 
                    null
                    : 
                    <BigIcon iconUrl={block.simple[0].iconOverTitle?.childImageSharp?.fluid?.src} /> 
                    }
                    <SectionTitle text={block.simple[0].title} />
                        {block?.simple[0]?.textSection?.map(text => {
                            return <LightText boldText={text.boldText != "-" ? text.boldText + " " : null} text={text.text} />
                        })}
                </Container>
            </Section>
            : 
            block.type == "full width image" ? 
            <SectionImage imageUrl={block.image[0]?.image?.childImageSharp?.fluid?.src} overlay={block.image[0]?.overlay ? true : false} >
                {block.image[0].title != "-" ? 
                    <Container>
                        <BigTitle center text={block.image[0].title} />

                        {block.image[0].textBlocks?.map(textBlock => {
                            return <LightText color="white" text={textBlock.text} />
                        })}

                        {block.image[0].checkTextBlocks ?
                            (<div style={{ maxWidth: '400px' }}>
                                {block.image[0].checkTextBlocks?.map(check => {
                            return <CheckIconText text={check.text} />
                        })}
                            </div>)
                            :
                            null
                        }

                    </Container>
                    :
                    null
                }
            </SectionImage>
            : 
            block.type == "points" ?
            <Section>
            <Container>
                <SectionTitle text={block.typePoints[0].title} />
                
                {block?.typePoints[0]?.points?.map(point => {
                    return <React.Fragment>
                        <ValuationIcon iconUrl={point?.icon?.childImageSharp?.fluid.src} text={point.title} color='var(--blue)' />
                        <LightText text={point.text} />
                    </React.Fragment>
                })}
            </Container>
        </Section>
        :
        null
        })}

       {/* <SectionImage imageUrl="/images/about/bg-glasses.jpg" overlay={true}>
            <Container>
                <BigTitle center text={"cms"} />
                <LightText color="white" text={"cms"} />
            </Container>
        </SectionImage>

        <Section>
            <Container>
                <SectionTitle text={"cms"} />
                <LightText text={"cms"} />
            </Container>
        </Section>

        <Section background='var(--lightGrey)'>
            <Container>
                <SectionTitle text={"cms"} />
                <LightText text={"cms"} />
                <LightText text={"cms"} />
            </Container>
        </Section>

        <SectionImage imageUrl="/images/about/bg-office4.jpg" height={800} />

        <Section>
            <Container>
                <SectionTitle text={"cms"} />
                <LightText text={"cms"} />
                <LightText text={"cms"} />
            </Container>
        </Section>

        <Section background='var(--lightGrey)'>
            <Container>
                <SectionTitle text={"cms"} />
                <LightText text={"cms"} />

                <LightText boldText={"cms"} text={"cms"} />
                <LightText boldText={"cms"}
                    text={"cms"} />
                <LightText boldText={"cms"}
                    text={"cms"} />
                <LightText boldText={"cms"}
                    text={"cms"} />
                <LightText boldText={"cms"}
                    text={"cms"} />

            </Container>
        </Section>

        <Section>
            <Container>
                <BigIcon iconUrl="/images/about/cloud.svg" />
                <SectionTitle text={"cms"} />
                <LightText boldText={"cms"} text={"cms"} />

                <LightText boldText={"cms"} text={"cms"} />
                <LightText boldText={"cms"} text={"cms"} />
                <LightText boldText={"cms"} text={"cms"} />
            </Container>
        </Section>

        <SectionImage imageUrl={"/images/about/mindmap.jpg"} Layout> overlay={true}>
            <Container>
                <BigTitle center text={"cms"} />
                <div style={{ maxWidth: '400px' }}>
                    <CheckIconText text={"cms"} />
                    <CheckIconText text={"cms"} />
                    <CheckIconText text={"cms"} />
                    <CheckIconText text={"cms"} />
                    <CheckIconText text={"cms"} />
                    <CheckIconText text={"cms"} />
                </div>


            </Container>
    </SectionImage> */}

        <Section imageUrl={'/img/pink.png'}>
            <Container>
                <SectionTitle color="white" text={cmsData.pink.title} />
                <LightText bottom="120" color="white" text={cmsData.pink.text} />
                <MainButton tag="contact-button" to={cmsData.pink.buttonPath} text={cmsData.pink.buttonText} />
            </Container>
        </Section>


        <EndSection to={cmsData?.endSection?.buttonPath} title={cmsData.endSection?.title} buttonText={cmsData.endSection.buttonText}
            iconUrl={cmsData?.endSection?.icon?.childImageSharp?.fluid?.src} />

    </Layout >
}

export default AboutComponent